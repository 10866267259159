/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useLocation } from 'react-router';
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';
import { string, shape } from 'prop-types';

import CarouselBuilderSkeleton from '../../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import useSalesforceProductRecommendationQuery from '../../../../../helpers/Personalization/useSalesforceProductRecommendationQuery';
import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import PersonalizedCarouselBuilder from '../../../EnterpriseID/Common/PersonalizedCarouselBuilder';

export default function EmailRecommendations({ cmsData }) {
    const {
        products_to_show = undefined,
        font_color,
        font_size,
        font_family,
        font_style,
        title,
    } = cmsData || {};

    const location = useLocation();
    const urlParams = QueryString.parse(location.search.slice(1));
    if (!urlParams?.utm_campaign?.includes('_SFRecs')) {
        return null;
    }

    const {
        loading, error, data, variables,
    } = useSalesforceProductRecommendationQuery({
        queryName: 'EmailRecommendations',
        campaignFeature: 'Email Recs',
        targeting: {
            pageType: 'category',
        },
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.productRecommendationsSFMulti?.[0],
        isFireImpression: true,
        page: { type: 'category' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'EmailRecommendations.js',
            message: 'Error loading data from saleforce api',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
    }

    if (data?.productRecommendationsSFMulti?.length > 0) {
        // show title from salesforce campaign response
        const titleFromSF = data?.productRecommendationsSFMulti?.[0]?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle;
        return (
            <ComponentErrorBoundary function="EmailRecommendations" module="PersonalizedContent">
                <PersonalizedCarouselBuilder
                    blockData={
                        {
                            products_to_show,
                            title: titleFromSF || title || 'Recommended For You',
                            title_font_color: font_color?.color,
                            title_font_size: font_size,
                            title_font_style: font_style,
                            title_font_family: font_family,
                            wcs_style: false,
                        }
                    }
                    productClickCallback={trackClickThrough}
                    products={data.productRecommendationsSFMulti?.[0]?.products || []}
                    nDisplayProducts={8}
                    demandType="XS"
                />
            </ComponentErrorBoundary>
        );
    }

    return null;
}

EmailRecommendations.propTypes = {
    cmsData: shape({
        title: string,
        font_color: shape({
            color: string.isRequired,
        }),
        font_size: string,
        font_style: string,
        font_family: string,
    }).isRequired,
};
